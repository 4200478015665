
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'

@Component
  export default class PurchaseStatusCell extends Cell {
  status = {
    icon: 'mdi-car',
    text: '',
    color: '',
  }
  // Methods

  // Getters
  get info () {
    return this.item
  }

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onItemChange (val) {
    const pipeline = val?.negotiation?.inspection?.appraisal?.deal?.lead?.pipeline
    if (pipeline.name === 'purchase') {
      this.setStatusValues({
        icon: pipeline.icon.main,
        description: val.acquisitionType.description,
        color: pipeline.color,
      })
    } else {
      this.setStatusValues({
        icon: pipeline.icon.main,
        description: pipeline.description,
        color: pipeline.color,
      })
    }
  }

  setStatusValues (status) {
    this.status.text = status.description
    this.status.color = status.color
    this.status.icon = status.icon
  }
  }
